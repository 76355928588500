
import { defineComponent, onMounted } from 'vue';
import { LoginParams } from '@/api/user/login';
import {useRoute, useRouter} from 'vue-router';
import { isNumber } from 'lodash';

import { GetQueryString } from '@/utils/utils';
import { useStore } from 'vuex';
import {
  GET_INFO,
  MY_ORGINFO,
  GET_MY_MENU_DATA_LIST,
  SET_SESSIONID_KEY
} from '@/store/modules/user/actions';

export default defineComponent({
  name: 'LoginSessionid',
  setup() {
    const router = useRouter();
    const store = useStore();
    const route = useRoute();
    const namespace:any = route.query.namespace;
    const path:any = route.query.path;
    onMounted(() => {
      if (window.location.href.indexOf('sessionid') > 0) {
        const sessionid: string | undefined = GetQueryString('sessionid');
        store.dispatch(`user/${SET_SESSIONID_KEY}`,sessionid);
        store.dispatch(`user/${MY_ORGINFO}`,{}as LoginParams).then((res: any) => {
          let org_id = null;
          if(namespace){
            const n_list = namespace.split('_');
            if(n_list.length>0){
              const org_id_str= n_list[n_list.length-1];
              if(isNumber(Number(org_id_str))){
                const f_org = res.org_list.data.find((item:any)=>item.id===Number(org_id_str));
                if(f_org){
                  org_id = f_org.id;
                }
              }
            }
          }
          if(!org_id){
            org_id = Number(res.org_list.data[0].id);
          }
          store.dispatch(`user/${GET_MY_MENU_DATA_LIST}`,{
            org_id: org_id,
          })
            .then(() => {
              store.dispatch(`user/${GET_INFO}` ).then(() => {});
            })
            .finally(() => {
              if(path){
                router.push({ path: path });
              }else {
                router.push({ path: '/' });
              }
            });
        });
      } else {
        router.push({ path: '/user/login' });
      }
    });

    return {};
  },
  components: {},
});
